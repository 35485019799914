import React from 'react'
import { graphql, HeadProps } from 'gatsby'
import { Company } from '../../contracts/company'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import OfferCardGrid from '../../components/offer-card-grid/offer-card-grid'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'

export const Head = ({ data }: HeadProps<{ company: Company }>) => {
  return (
    <title>{data.company.name} - Disco Deals</title>
  )
}

export default (props: any) => {
  const company = props.data.company as Company
  const offers = company.offers.slice(0, 6)
  const availableOffers = company.availableOffers.slice(0, 6)

  const image = getImage(company.image)
  
  return (
    <main className='pt-4 container'>
      <Breadcrumbs className='mb-2' crumbs={[
        ['Home', '/'],
        [company.name, `/companies/${company.id.toLowerCase().replace(/ /g, '-')}`]
      ]} />
      
      <div className='text-center mb-4'>
        {image && 
          <GatsbyImage
            image={image}
            alt={company.name}
            objectFit="contain"
            className="w-80 h-30 mb-4 bg-white border-4 border-white"
          />
        }
        <p className='text-4xl lg:text-5xl text-gray-700 dark:text-gray-200'>{company.description}</p>

      </div>

      <section>
        <h3 className='section-title text-link'>Offers by {company.name}</h3>
        <OfferCardGrid offers={offers} />
      </section>

      <section >
        <h3 className='section-title text-link'>Offers available through {company.name}</h3>
        <OfferCardGrid offers={availableOffers} />
      </section>
    </main>
  )
}

export const query = graphql`
query($id: String) {
  company(id: {eq: $id}) {
    id
    name
    memberLink
    description
    offers {
      ...OfferDisplay
    }
    availableOffers {
      ...OfferDisplay
    }
    image {
      childImageSharp {
        gatsbyImageData(width: 320)
      }
    }
  }
}
`